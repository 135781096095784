import { post, deletes, get, downFile } from 'common/request'
export default {
//  分页
list:args => get('/scProduct/list', args),
// 新增
add:args => post('/scProduct/add', args),
// 编辑
edit:args => post('/scProduct/update', args),
// 删除
dels:args => deletes(`/scProduct/deleteById?id=${args}`),
// 详情
gridDetail:args => get(`/scGridWorker/getById?id=${args}`),
//导出
export: args => downFile('/scGridWorker/export', args),
//批量删除
 deleteBatch:args => post(`/scGridWorker/deleteBatch`,args),
}
